var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"account"},[_c('div',{staticClass:"toaster show"},[(_vm.alert.show)?_c('div',{staticClass:"toaster__container",class:{
        'toaster--success': _vm.alert.status === 'success',
        'toaster--error': _vm.alert.status === 'fail',
      }},[_c('div',[_c('span',{staticClass:"d-block text--small text--600 text--white"},[_vm._v(_vm._s(_vm.alert.title))]),_c('span',{staticClass:"d-block text--tiny text--400 text--white"},[_vm._v(_vm._s(_vm.alert.description))])])]):_vm._e()]),_c('div',{staticClass:"account__header d-flex justify-content-between"},[_c('div',[_vm._v(" ")]),_c('div',{staticClass:"account__header__items d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"account__logo"}),_c('Country')],1)]),_c('div',{staticClass:"account__container"},[_vm._m(0),_c('div',{staticClass:"account__right d-flex flex-column"},[_c('div',{staticClass:"account__form__container vh-100"},[_c('div',{staticClass:"account__form__wrapper d-flex flex-column"},[_vm._m(1),_c('div',{staticClass:"account__body"},[_c('div',{staticClass:"account__form"},[_c('div',{staticClass:"form__item form__action d-flex align-items-center justify-content-between"},[_c('div',{staticStyle:{"position":"relative","z-index":"3"}},[_c('button',{staticClass:"button form__button form__button--lg",on:{"click":_vm.goToScanQrCode}},[_vm._v(" next ")]),_c('img',{staticStyle:{"position":"absolute","left":"-5rem","top":"-2.5rem","z-index":"-1"},attrs:{"src":"/assets/img/dotted-bg.svg","alt":"","srcset":""}})])])])]),_vm._m(2)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"account__left"},[_c('div',{staticClass:"account__left__bg"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"account__head"},[_c('h5',{staticClass:"account__head__title"},[_vm._v(" Download Google Authenticator ")]),_c('p',{staticClass:"account__head__subtitle"},[_vm._v(" Search for Google Authenticator App in your favourite app store with your smart phone and download the app to your phone. Click the next button below after downloading the app to your mobile device. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"account__info mt-auto"},[_c('div',{staticClass:"account__info__container d-flex align-items-center"},[_c('div',{staticClass:"account__info__icon"},[_c('img',{attrs:{"src":"assets/img/help.svg","alt":"","srcset":""}})]),_c('div',{},[_c('span',{staticClass:"account__text"},[_vm._v("Expert support available to you 24 /7.")]),_c('span',{staticClass:"account__text account__info__link text--capital"},[_vm._v(" Contact Support ")])])])])
}]

export { render, staticRenderFns }